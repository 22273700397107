exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-pro-tsx": () => import("./../../../src/pages/api-pro.tsx" /* webpackChunkName: "component---src-pages-api-pro-tsx" */),
  "component---src-pages-api-terms-tsx": () => import("./../../../src/pages/api-terms.tsx" /* webpackChunkName: "component---src-pages-api-terms-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-storefront-tsx": () => import("./../../../src/pages/storefront.tsx" /* webpackChunkName: "component---src-pages-storefront-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

